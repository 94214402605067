import { SharedService } from '@/app/shared/services/shared.service';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
// import { Paginate } from '@/app/shared/interface/pagination.interface';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() public total: number | undefined;
  @Input() public currentPage: number | undefined;
  @Input() public pageSize: number | undefined;
  @Input() public paginateRange: number = 7;

  @Output() setPage: EventEmitter<number> = new EventEmitter();

  public paginate: any; // Pagination use only
  
  constructor(private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router) { }

  // Detect changes
  ngOnChanges(changes: SimpleChanges) {
    this.total = changes['total'] ? changes['total'].currentValue : this.total;
   
    this.currentPage = changes['currentPage'] ? changes['currentPage'].currentValue : this.currentPage;
    this.pageSize = changes['pageSize'] ? changes['pageSize'].currentValue : this.pageSize;
    // Get Pager For Pagination
    this.paginate = this.sharedService.getPager(this.total!, this.currentPage!, this.pageSize!, this.paginateRange);
  }

  // Set Page
  pageSet(page: number) {
    this.setPage.emit(page);  // Set Page Number  
  }

  onPageChanged(e: any) {
    if (e.target.value != "") {
      this.currentPage = Number(e.target.value);
      if (this.currentPage > this.paginate.totalPages) {
        this.currentPage = this.paginate.totalPages;
      }
    }
    else {
      this.currentPage = 1;
    }
    this.router.navigate([], { relativeTo: this.route, queryParams: { page: this.currentPage}, queryParamsHandling: 'merge', replaceUrl: true });
    this.setPage.emit(this.currentPage);
  }

}
