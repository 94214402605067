<!-- <app-loader *ngIf="loadingStatus$ | async"></app-loader> -->
<div class="container-fluid">
    <div class="row">
        <div [class]="gridClass">
            <div class="card">
                <div class="card-body">
                    <div class="title-header" *ngIf="title">
                        <div class="d-flex align-items-center">
                            <h5>{{ title }}</h5>
                            <ng-content select="[option]"></ng-content>
                        </div>
                        <ng-content select="[button]"></ng-content>
                    </div>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>