import { Injectable } from "@angular/core";
import { PostModel } from "../interfaces/article.interface";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class SharedService {
    public sidebarLoading: boolean = false;
    public collapseSidebar: boolean = false;
    public search: boolean = false;
    public selectedProductId: number = 0;
    public selectedUsername: string = "";
    public selectedPostSlug: string | undefined;
    public selectedPostCatSlug: string | undefined;
    public showBackButton: boolean = false;
    public selectedPost: PostModel | undefined;

    private qPage: number = -1;
    private qPageSize: number = -1;
    constructor(private route: ActivatedRoute,
        private router: Router) {
    }
    getPager(totalItems: number, currentPage: number, pageSize: number, paginateRange: number) {
        this.qPage = this.getCurrentPage();
        this.qPageSize = this.getPageSize();

        if (this.qPageSize > 0) {
            pageSize = this.qPageSize;
        }

        // calculate total pages
        let totalPages = Number(Math.ceil(Number(totalItems) / Number(pageSize)));

        // Paginate Range
        paginateRange = paginateRange ?? 0;

        // ensure current page isn't out of range
        if (this.qPage > 0 && this.qPage <= Number(totalPages)) {
            currentPage = this.qPage;
        } else if (Number(currentPage) < 1) {
            currentPage = 1;
        } else if (Number(currentPage) > Number(totalPages)) {
            currentPage = Number(totalPages);
        }

        let startPage: number, endPage: number;
        if (Number(totalPages) <= Number(paginateRange)) {
            // Less than or equal to the paginateRange
            startPage = 1;
            endPage = Number(totalPages);
        } else if (Number(currentPage) <= Number(Math.floor(Number(paginateRange) / 2))) {
            // Near the beginning
            startPage = 1;
            endPage = Number(paginateRange);
        } else if (Number(currentPage) >= Number(totalPages) - Number(Math.floor(Number(paginateRange) / 2))) {
            // Near the end
            startPage = Number(totalPages) - Number(paginateRange) + 1;
            endPage = Number(totalPages);
        } else {
            // In the middle
            startPage = Number(currentPage) - Number(Math.floor(Number(paginateRange) / 2));
            endPage = Number(currentPage) + Number(Math.floor(Number(paginateRange) / 2));
        }

        // calculate start and end item indexes
        let startIndex = (Number(currentPage) - 1) * Number(pageSize);
        let endIndex = Math.min(Number(startIndex) + Number(pageSize) - 1, Number(totalItems) - 1);


        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((Number(endPage) + 1) - Number(startPage)).keys()).map(i => Number(startPage) + Number(i));
        if (currentPage !== this.qPage && currentPage > 0 && this.qPage > 0) {
            this.router.navigate([], { relativeTo: this.route, queryParams: { page: currentPage }, queryParamsHandling: 'merge', replaceUrl: true });
        }

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    getCurrentPage(): number {
        if (this.route.snapshot.queryParams['page'] && typeof parseInt(this.route.snapshot.queryParams['page']) === 'number') {
            return parseInt(this.route.snapshot.queryParams['page']);
        }
        return -1;
    }

    getPageSize(): number {
        if (this.route.snapshot.queryParams['pageSize'] && typeof parseInt(this.route.snapshot.queryParams['pageSize']) === 'number') {
            return parseInt(this.route.snapshot.queryParams['pageSize']);
        }
        return -1;
    }
}