<ng-template #deleteModal let-modal>
    <div class="modal-body">
        <i class="ri-delete-bin-line icon-box"></i>
        <h5 class="modal-title">{{ (title ? title : 'delete_item') | translate }}</h5>
        <p>{{ (message ? message : 'deleted_message') | translate }} </p>
        <div class="button-box">
            <app-button 
                [class]="'btn-md fw-bold btn btn-secondary'" 
                [spinner]="false"
                [id]="'delete_no_btn'" 
                (click)="modal.dismiss('Cancel')">
                {{ 'no' | translate }}
            </app-button>
            <app-button 
                [class]="'btn-theme btn-md fw-bold btn'" 
                [id]="'delete_yes_btn'"
                (click)="delete(modal)">
                {{ 'yes' | translate }}
            </app-button>
        </div>
    </div>
</ng-template>