import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Observable } from 'rxjs';
// import { Currency } from '@/app/shared/interface/currency.interface';

@Pipe({
  standalone: true,
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  // selectedCurrency$: Observable<Currency>;

  public symbol: string = 'VND';
  // public selectedCurrency: Currency;

  // constructor(private currencyPipe: CurrencyPipe) {
  //   // this.selectedCurrency$.subscribe(currency => this.selectedCurrency = currency);
  // }

  transform(value: number | undefined, position: 'before_price' | 'after_price' | string = 'after_price'): string | number {
    if(!value) {
      value = 0;
    };
    value = Number(value);
    // value = (value * this.selectedCurrency?.exchange_rate);

    // this.symbol = this.selectedCurrency?.symbol || 'VND';
    // position = this.selectedCurrency?.symbol_position;

    // let formattedValue = this.currencyPipe.transform(value, this.symbol);
    // formattedValue = formattedValue?.replace(this.symbol, '')!;

    const currenyFormat = Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: "VND"
    });
    return `${currenyFormat.format(value)}`;

    // if (position === 'before_price') {
    //   return `${this.symbol} ${value}`;
    // } else {
    //   return `${value} ${this.symbol}`;
    // }
  }
}
