import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { IsAuthurization } from '../interfaces/role.interface';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {

  @Input('hasPermission') permission: number | number[];

  public permissions: number[] = [];

  private isViewCreated = false;

  constructor(private templateRef: TemplateRef<string>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService) { }

  ngOnInit() {
    this.authService.getPermission().subscribe(permissions => {
      this.permissions = permissions;
      this.checkPermissions();
    });
  }

  private checkPermissions() {
    if (IsAuthurization(this.permissions, this.permission)) {
      if (!this.isViewCreated) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.isViewCreated = true;
      }
    } else {
      if (this.isViewCreated) {
        this.viewContainerRef.clear();
        this.isViewCreated = false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['permission'] && !changes['permission'].firstChange) {
      this.checkPermissions();
    }
  }

}
