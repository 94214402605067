import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Observable, of } from "rxjs";
import { JwtPayload, jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(body: string) {
    return this.http.post(`${environment.baseURL}session`, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-Admin-Site': 'true'
      }
    });
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
  }

  refreshToken(refreshToken: string) {
    return this.http.post(`${environment.baseURL}refresh-token`, refreshToken, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
  
  getPermission(): Observable<number[]> {
    let token = localStorage.getItem('token');
    if (!token) {
      return of([]);
    }
    const decoded = jwtDecode<TokenModel>(token);
    if (decoded.Permissions) {
      const permissions = decoded.Permissions.split(',').map(v => parseInt(v)).filter(v => !isNaN(v));
      return of(permissions);
    }
    return of([]);
  }
}

class TokenModel implements JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  UserName: string;
  Permissions: string;
  Status: string;
}
