<ng-template #confirmationModal let-modal>
    <div class="modal-body">
        <i class="ri-question-line icon-box wo-bg"></i>
        <h5 class="modal-title">{{(title ? title : 'duplicate_item') | translate}}</h5>
        <p>{{ (message ? message : 'duplicate_message') | translate}}</p>
        <div class="button-box">
            <app-button
                [class]="'btn btn-md fw-bold btn-theme-outline btn-confirm-no border-0'"
                [spinner]="false"
                [id]="'confirm_no_btn'"
                (click)="confirm(false); modal.dismiss('Cancel')">
                <img src="assets/svg/thumb down.svg" />
                &nbsp;
                Không
            </app-button>
            <app-button
                [class]="'btn btn-confirm-yes theme-bg-color btn-md fw-bold text-light'"
                [id]="'confirm_yes_btn'"
                (click)="confirm(true)">
                <img src="assets/svg/thumb up.svg" />
                &nbsp;
                Có
            </app-button>
        </div>
    </div>
</ng-template>