<div  class="d-flex align-items-center" style="gap: 8px">
  Đi đến trang:
  <input class="form-control" style="max-width: 60px; padding: 8px; text-align: center" [value]="paginate?.currentPage"
    (change)="onPageChanged($event)" />
</div>
<ul class="pagination justify-content-center"
  *ngIf="paginate?.pages && paginate?.pages.length">
  <li class="page-item" [ngClass]="{ disabled: paginate.currentPage === 1 }">
    <a class="page-link" tabindex="-1" aria-disabled="true" routerLink="." [queryParams]="{page: 1}" [queryParamsHandling]="'merge'" [replaceUrl]="true"
      (click)="pageSet(1)">
      <i class="ri-arrow-left-double-line"></i>
    </a>
  </li>
  <li class="page-item" [ngClass]="{ disabled: paginate.currentPage === 1 }">
    <a class="page-link" tabindex="-1" aria-disabled="true" routerLink="."
      [queryParams]="{page: paginate?.currentPage - 1}" [replaceUrl]="true"
      (click)="pageSet(paginate?.currentPage - 1)">
      <i class="ri-arrow-left-s-line"></i>
    </a>
  </li>
  <li class="page-item" *ngFor="let page of paginate?.pages"
    [ngClass]="{ 'active disabled': paginate.currentPage == page }" style="opacity: 1;">
    <a class="page-link" routerLink="." [queryParams]="{page: page}" [queryParamsHandling]="'merge'" [replaceUrl]="true" (click)="pageSet(page)">{{ page
      }}</a>
  </li>
  <li class="page-item" [ngClass]="{ disabled: paginate?.currentPage == paginate?.totalPages }">
    <a class="page-link" routerLink="." [queryParams]="{page: paginate?.currentPage + 1}" [queryParamsHandling]="'merge'" [replaceUrl]="true"
      (click)="pageSet(paginate?.currentPage + 1)">
      <i class="ri-arrow-right-s-line"></i>
    </a>
  </li>
  <li class="page-item" [ngClass]="{ disabled: paginate?.currentPage == paginate?.totalPages }">
    <a class="page-link" routerLink="." [queryParams]="{page: paginate?.totalPages}" [queryParamsHandling]="'merge'" [replaceUrl]="true"
      (click)="pageSet(paginate?.totalPages)">
      <i class="ri-arrow-right-double-line"></i>
    </a>
  </li>
</ul>
<div class="d-flex align-items-center" style="gap: 8px">
  Tổng số: <span>{{total}}</span>
</div>